<template>
  <form class="new-ticket" @click="isFocusMultipleField = false">
    <div class="new-ticket__header">
      <Heading level="4">{{ $t('dashboard.info_about_auto') }}</Heading>

      <div class="new-ticket__btn-reset" @click="openModal">
        <span class="new-ticket__btn-text">
          {{ $t('new_ticket_info.clear_all') }}</span
        >
        <ButtonClear />
      </div>
      <Popup :dialog.sync="modal" class="popup">
        <div class="popup__clear-icon">
          <svg-icon name="cross"></svg-icon>
        </div>
        <Heading level="4" class="popup__title">Очистити все</Heading>
        <div class="popup__subtitle">
          Ви впевнені що хочете очистити всі дані?
        </div>
        <div class="popup__buttons-box">
          <ButtonBase
            color="white"
            size="md-bg"
            class="popup__btn"
            @click.native="closeModal"
          >
            <span class="new-ticket__btn-titles">
              {{ $t('new_ticket_info.cancel') }}
            </span>
          </ButtonBase>
          <ButtonBase
            color="blue"
            size="md-bg"
            class="popup__btn"
            @click.native="acceptResetFormHandler"
          >
            {{ $t('popup.confirm') }}
          </ButtonBase>
        </div>
      </Popup>
    </div>

    <template
      v-if="ticketPricing.length && carsBrandsList.length && countries.length"
    >
      <div class="new-ticket__form-group">
        <div class="new-ticket__form-group-item full-name">
          <label class="new-ticket__lables">
            {{ $t('new_ticket_info.driver_name') }}
          </label>
          <TextField
            id="name"
            v-model.trim="driverFullName"
            name="name"
            required
            :placeholder="driverFullName"
            class="new-ticket__inputs"
            type="text"
            :error="
              ($v.driverFullName.$dirty && !$v.driverFullName.required) ||
              ($v.driverFullName.$dirty && $v.driverFullName.$invalid)
            "
            :error-txt="driverFullNameErrorText"
            @isFocus="clearDriverFullNameField"
            @blur="$v.driverFullName.$touch()"
            @input="inputFullNameHandler"
          />
        </div>
        <div class="new-ticket__form-group-item email">
          <label class="new-ticket__lables">E-mail</label>
          <TextField
            id="email"
            v-model.trim="driverEmail"
            type="email"
            name="email"
            required
            :placeholder="driverEmail"
            class="new-ticket__inputs"
            :error="
              ($v.driverEmail.$dirty && !$v.driverEmail.required) ||
              ($v.driverEmail.$dirty && $v.driverEmail.$invalid)
            "
            :error-txt="driverEmailErrorText"
            @isFocus="clearDriverEmailField"
            @blur="$v.driverEmail.$touch()"
          />
        </div>
        <div class="new-ticket__form-group-item phone">
          <label class="new-ticket__lables">
            {{ $t('new_ticket_info.phone_number') }}
          </label>
          <TextField
            id="phone"
            v-model.trim="driverPhone"
            type="text"
            name="phone"
            required
            placeholder="+ __ ___ ___ __ __"
            class="new-ticket__inputs"
            :error="
              ($v.driverPhone.$dirty && !$v.driverPhone.required) ||
              ($v.driverPhone.$dirty && $v.driverPhone.$invalid)
            "
            :error-txt="driverPhoneErrorText"
            @isFocus="clearDriverPhoneField"
            @blur="$v.driverPhone.$touch()"
          />
        </div>
        <div class="new-ticket__form-group-item plate_number">
          <label class="new-ticket__lables">
            {{ $t('new_ticket_info.auto_plate_number') }}
          </label>
          <MultipleField
            v-model="carNumber"
            :items="countries"
            :is-focus="isFocusMultipleField"
            class="new-ticket__inputs"
            :error="
              ($v.carNumber.value.$dirty && !$v.carNumber.value.required) ||
              ($v.carNumber.value.$dirty && $v.carNumber.value.$invalid)
            "
            :error-txt="carNumberErrorText"
            @click.native.stop
            @focus="clearCarNumberField"
            @blur="blurCarNumberHandler"
          />
        </div>
        <div class="new-ticket__form-group-item brand">
          <label class="new-ticket__lables">
            {{ $t('new_ticket_info.brand') }}
          </label>
          <SelectField
            v-model="carBrand"
            :items="carsBrandsList"
            append-to=".new-ticket__form-group-item.brand .autocomplete-box"
            class="new-ticket__inputs"
            :error="$v.carBrand.$dirty && !$v.carBrand.required"
            :error-txt="carBrandErrorText"
            @select="fetchAllCarModels"
            @focus="clearCarBrandField"
            @blur="$v.carBrand.$touch()"
          />
        </div>
        <div class="new-ticket__form-group-item model">
          <label class="new-ticket__lables">
            {{ $t('new_ticket_info.model') }}
          </label>
          <SelectField
            v-model="carModel"
            :items="carModelsList"
            append-to=".new-ticket__form-group-item.model .autocomplete-box"
            class="new-ticket__input"
            :error="$v.carModel.$dirty && !$v.carModel.required"
            :error-txt="carModelErrorText"
            @focus="clearCarModelField"
            @blur="$v.carModel.$touch()"
          />
        </div>

        <div class="new-ticket__form-group-item passengers">
          <label class="new-ticket__lables">
            {{ $t('new_ticket_info.passangers') }}
          </label>
          <CountField
            v-model.trim="passengersCount"
            class="new-ticket__inputs"
            :error="
              ($v.passengersCount.$dirty && !$v.passengersCount.required) ||
              ($v.passengersCount.$dirty && $v.passengersCount.$invalid)
            "
            :error-txt="passengersCountErrorText"
            @decrement="passengersCount--"
            @increment="passengersCount++"
            @focus="clearPassengersCountField"
            @blur="$v.passengersCount.$touch()"
          />
        </div>
      </div>

      <div class="new-ticket__form-select">
        <Heading level="4">{{ $t('new_ticket_info.type_of_auto') }}</Heading>
        <div class="new-ticket__form-select-label">
          <span class="new-ticket__btn-titles">
            {{ $t('new_ticket_info.auto_truck') }}
          </span>
          <span class="new-ticket__btn-values">
            ({{ ticketPricingRange }} грн)
          </span>
        </div>
        <div class="new-ticket__form-group-item">
          <label class="new-ticket__lables">
            {{ $t('new_ticket_info.subtype_auto') }}
          </label>
          <div class="new-ticket__btn-form">
            <ButtonBase
              v-for="item in formattedTicketPricing"
              :key="item.type_auto"
              color="white"
              size="m"
              class="new-ticket__btn-subtype"
              :class="{
                active: selectedCarType
                  ? item.type_auto === selectedCarType.type_auto
                  : null,
              }"
              @click.native="selectCarTypeHandler(item)"
            >
              <span class="new-ticket__btn-titles">
                {{ $t(`new_ticket_info.${item.type_auto}`) }}
              </span>
              <span class="new-ticket__btn-values">
                ({{ item.price }} грн)
              </span>
            </ButtonBase>
          </div>
        </div>
      </div>

      <div class="new-ticket__footer">
        <Heading v-if="selectedCarType" level="3" class="new-ticket__price"
          >Ціна: {{ ticketPrice }} грн.</Heading
        >
        <div class="new-ticket__btn-form new-ticket__btn-form-footer">
          <ButtonBase color="white" size="md-bg" @click.native="goBack">
            <span class="new-ticket__btn-titles"> Назад </span>
          </ButtonBase>
          <ButtonBase
            color="blue"
            size="md-bg"
            :disabled="!isValidForm"
            @click.native="submitHandler"
          >
            {{ $t('new_ticket_info.next') }}
          </ButtonBase>
        </div>
      </div>
    </template>
    <Loader v-else />
  </form>
</template>

<script>
import validate from '@/mixins/validationMixin';
import { setToLS } from '@/library/helpers';
import Heading from '@/elements/Heading/Heading.vue';

import ButtonClear from '@/elements/Buttons/ButtonClear.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';

import TextField from '@/elements/Inputs/TextField.vue';
import MultipleField from '@/elements/Inputs/MultipleField.vue';
import CountField from '@/elements/Inputs/CountField.vue';
import SelectField from '@/elements/Inputs/SelectField.vue';

import Popup from '@/elements/Popup/Popup.vue';
import Loader from '@/elements/Loader/Loader.vue';

export default {
  components: {
    Heading,
    ButtonClear,
    TextField,
    ButtonBase,
    MultipleField,
    CountField,
    SelectField,

    Popup,
    Loader,
  },
  mixins: [validate],
  data() {
    return {
      driverFullName: '',
      driverEmail: '',
      driverPhone: '',

      countries: [],
      carNumber: {
        country: {
          id: 223,
          iso: 'UA',
          iso3: 'UKR',
          name: 'UKRAINE',
          'number-code': '804',
          'phone-code': '380',
          'simple-name': 'Ukraine',
        },
        value: '',
      },

      carsBrandsList: [],
      carModelsList: [],
      carBrand: null,
      carModel: null,

      passengersCount: 1,

      ticketPricing: [],
      selectedCarType: null,

      ticketPrice: 0,
      modal: false,
      isFocusMultipleField: false,
    };
  },

  computed: {
    formattedTicketPricing() {
      return this.ticketPricing.map((item) => {
        return {
          ...item,
          price: parseInt(item.price).toFixed(),
        };
      });
    },
    ticketPricingRange() {
      const ticketPricingList = this.formattedTicketPricing.map((item) =>
        parseInt(item.price)
      );
      const minPrice = Math.min(...ticketPricingList);
      const maxPrice = Math.max(...ticketPricingList);

      return `${minPrice}-${maxPrice}`;
    },

    driverFullNameArray() {
      return this.driverFullName.split(' ');
    },

    driverLastName() {
      return this.driverFullNameArray[0];
    },

    driverFirstName() {
      return this.driverFullNameArray[1];
    },

    driverSecondName() {
      return this.driverFullNameArray[2];
    },

    ticketInfoFormData() {
      const ticket = {
        last_name: this.driverLastName,
        first_name: this.driverFirstName,
        email: this.driverEmail,
        telephone_number: this.driverPhone,
        type_auto: this.selectedCarType ? this.selectedCarType.type_auto : null,
        ticket_price: this.ticketPrice,
        country_registration: this.carNumber.country,
        auto_registration_plate: this.carNumber.value.toUpperCase(),
        car_brand: this.carBrand,
        car_model: this.carModel,
        count_of_passengers: this.passengersCount,
      };

      if (this.driverSecondName) {
        ticket.second_name = this.driverSecondName;
      }

      return ticket;
    },

    isValidForm() {
      return !this.$v.$invalid && !!this.selectedCarType;
    },
  },
  mounted() {
    this.fetchTicketPricing();
    this.fetchAllCarBrands();

    const isEmptyRouteParams = !Object.keys(this.$route.params).length;

    isEmptyRouteParams
      ? this.fetchAllCountries()
      : this.setCarNumberFromDashboard();
  },

  methods: {
    async fetchTicketPricing() {
      const res = await this.$store.dispatch('getTicketPricing');
      this.ticketPricing = res;
    },
    async fetchAllCarBrands() {
      const res = await this.$store.dispatch('getAllCarBrands');
      this.carsBrandsList = res;
    },
    async fetchAllCarModels() {
      const res = await this.$store.dispatch(
        'getAllCarModels',
        this.carBrand.id
      );
      this.carModelsList = res;
    },
    async fetchAllCountries() {
      const res = await this.$store.dispatch('getAllCountries');
      this.countries = res;
    },
    async setCarNumberFromDashboard() {
      await this.fetchAllCountries();

      const countryRegistrationId = this.countries.find(
        (item) => item.id === this.$route.params.carNumber.country.id
      );

      this.carNumber = {
        country: countryRegistrationId,
        value: this.$route.params.carNumber.value,
      };
    },

    clearCarNumberField() {
      this.isFocusMultipleField = true;
      this.$v.carNumber.value.$reset();
    },
    clearCarBrandField() {
      this.$v.carBrand.$reset();
    },
    clearCarModelField() {
      this.$v.carModel.$reset();
    },
    clearDriverFullNameField() {
      this.$v.driverFullName.$reset();
    },
    clearDriverEmailField() {
      this.$v.driverEmail.$reset();
    },
    clearPassengersCountField() {
      this.$v.passengersCount.$reset();
    },
    clearDriverPhoneField() {
      this.$v.driverPhone.$reset();
    },
    blurCarNumberHandler() {
      this.isFocusMultipleField = false;
      this.$v.carNumber.value.$touch();
    },

    inputFullNameHandler(fullName) {
      this.driverFullName = fullName
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },

    goBack() {
      this.$router.push('/');
    },

    submitHandler() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      setToLS('new-ticket', this.ticketInfoFormData);
      this.goToNextStep();
    },

    goToNextStep() {
      this.$router.push({ name: 'newTicketDate' });
    },

    openModal() {
      this.modal = true;
    },

    closeModal() {
      this.modal = false;
    },

    resetForm() {
      this.driverFullName = '';
      this.driverEmail = '';
      this.driverPhone = '';
      this.carNumber = {
        country: {
          id: 223,
          iso: 'UA',
          iso3: 'UKR',
          name: 'UKRAINE',
          'number-code': '804',
          'phone-code': '380',
          'simple-name': 'Ukraine',
        },
        value: '',
      };
      this.carBrand = null;
      this.carModel = null;
      this.carModelsList = [];
      this.passengersCount = 1;
      this.selectedCarType = null;

      this.$v.$reset();
    },

    selectCarTypeHandler(item) {
      this.selectedCarType = {
        type_auto: item.type_auto,
        price: item.price,
      };

      this.ticketPrice = this.selectedCarType.price;
    },

    acceptResetFormHandler() {
      this.closeModal();
      this.resetForm();
    },
  },
};
</script>
<style lang="sass" scoped>
.new-ticket__form-group
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(365px, 1fr))
  column-gap: 20px
  row-gap: 32px
  margin: 8px 0 32px

.model
  @media screen and (max-width: 1199px)
    order: 1

.email
  @media screen and (max-width: 1199px)
    order: 2

.phone
  @media screen and (max-width: 1199px)
    order: 3

.passengers
  @media screen and (max-width: 1199px)
    order: 4

.new-ticket__form-group-item
  display: flex
  flex-direction: column
  gap: 8px

.new-ticket__header
  display: flex
  align-items: center
  justify-content: space-between

.new-ticket__form-select-label
  width: 220px
  height: 64px
  font-size: 18px
  background-color: var(--primary-color-white)
  border: 1px solid var(--primary-color-dark-grey-3)
  border-radius: var(--primary-border-radius)
  color: var(--primary-color-dark-blue)
  align-items: center
  justify-content: center
  margin-bottom: 32px

.new-ticket__form-select-label,
.new-ticket__btn-subtype
  display: flex
  flex-direction: column
  font-weight: 600
  text-transform: unset!important

.new-ticket__form-select-label,
.new-ticket__btn-subtype.active
  border: none
  background-color: var(--secondary-color-dark-blue)
  color: var(--primary-color-white)

.new-ticket__btn-reset
  display: flex
  align-items: center
  gap: 18px
  cursor: pointer

.new-ticket__btn-titles
  line-height: 32px

.new-ticket__btn-values
  font-weight: normal
  font-size: 16px
  line-height: 24px

.new-ticket__btn-form
  display: flex
  gap: 20px
  position: relative

.new-ticket__btn-form-footer
  gap: 32px

.new-ticket__footer
  display: flex
  flex-direction: column
  align-items: center
  margin-top: 48px
  margin-bottom: 170px
  border-top: 1px solid var(--primary-color-light-grey-2)
  padding-top: 40px

  @media screen and (max-width: 1199px)
    margin-bottom: 70px

.new-ticket__price
  margin-top: 0
  margin-bottom: 40px
</style>
