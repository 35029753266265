<template>
  <button type="button" class="clear-btn">
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="43.2125"
        height="1.9642"
        transform="matrix(0.705882 0.70833 -0.705882 0.70833 2.38477 0)"
        fill="#FB5151"
      />
      <rect
        width="43.2125"
        height="1.9642"
        transform="matrix(-0.705882 0.70833 0.705882 0.70833 31.3906 0)"
        fill="#FB5151"
      />
    </svg>
    <slot />
  </button>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
.clear-btn
  display: flex
  align-items: center
  justify-content: center
  position: relative
  height: 64px
  width: 68px
  border: 1px solid var(--btn-clear-color-red-1)
  border-radius: 4px
  transition: 0.3s
  cursor: pointer

.clear-btn:hover
  background-color: var(--btn-clear-color-red-hover)

.clear-btn[disabled]
  border: 1px solid var(--primary-color-dark-grey-1)
  cursor: auto

.clear-btn[disabled]:hover
  background-color: #fff

.clear-btn[disabled] svg rect
  fill: var(--primary-color-dark-grey-1)

.clear-btn:focus
  background-color: var(--btn-clear-color-red-focus)
</style>
